import Gen from "@helper/Gen";
var fo_router = [{
    name: "Index",
    path: "",
    component: () => import("./frontend/Index.vue"),
  },
  {
    name: "About",
    path: "sdi-al-hidayah",
    component: () => import("./frontend/About.vue"),
  },
  {
    name: "AboutFoundation",
    path: "yayasan-al-hidayah",
    component: () => import("./frontend/AboutFoundation.vue"),
  },
  {
    name: "NewsArticle",
    path: "berita-artikel",
    component: () => import("./frontend/NewsArticle.vue"),
  },
  {
    name: "NewsArticleCategory",
    path: "berita-artikel/tipe/:tipe?/:kategori?",
    component: () => import("./frontend/NewsArticle.vue"),
  },
  {
    name: "NewsArticleTags",
    path: "berita-artikel/tags/:tags",
    component: () => import("./frontend/NewsArticle.vue"),
  },

  {
    name: "NewsArticleDetail",
    path: "berita-artikel/:slug",
    component: () => import("./frontend/NewsArticleDetail.vue"),
  },
  {
    name: "Academic",
    path: "kalender-akademik",
    component: () => import("./frontend/CalAcademic.vue"),
  },
  {
    name: "Curriculum",
    path: "kurikulum",
    component: () => import("./frontend/Curriculum.vue"),
  },
  {
    name: "Gallery",
    path: "galeri/:kategori?",
    component: () => import("./frontend/Gallery.vue"),
  },
  {
    name: "Contact",
    path: "hubungi-kami",
    component: () => import("./frontend/Contact.vue"),
  },
  {
    name: "PPDB",
    path: "daftar",
    component: () => import("./frontend/PPDB.vue"),
  },
  {
    name: "Toc",
    path: "syarat-ketentuan",
    component: () => import("./frontend/Toc.vue"),
  },
  {
    name: "Privacy",
    path: "kebijakan-privasi",
    component: () => import("./frontend/Privacy.vue"),
  },
  {
    name: "Other",
    path: "lembaga",
    component: () => import("./frontend/Other.vue"),
  },
  {
    name: "ThankYou",
    path: "terima-kasih",
    meta: {
      noNavigation: true,
    },
    component: () => import("./frontend/ThankYou.vue"),
  },
  {
    path: "*",
    redirect: {
      name: "404"
    }
  },
  {
    name: "404",
    path: "404",
    component: () => import("./frontend/Page404.vue"),
  },
];
fo_router.map((v) => {
  v.beforeEnter = (to, from, next) => {
    if (typeof $ != "undefined") $("html,body").scrollTop(0);
    next();
  };
});
var data = [{
    name: "logout",
    path: "logout",
    beforeEnter(from, to, next) {
      Gen.delCookie("fotk");
      Gen.delStorage("fotk");
      if (typeof App != "undefined") App.user = null;
      next({
        name: "Home",
      });
    },
  },
  {
    path: "/",
    component: () => import("@/layout/FoLayout.vue"),
    children: fo_router,
  },
];
export default data;