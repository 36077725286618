<script>
import Vue from 'vue';
import env from './env';

/* Window Variable */
window.jsTagPlugins = []
window.jsPlugins = []
window.cssPlugins = []

/* CSS */
import "@assets/global.scss"

// Loading Overlay
import 'vue-loading-overlay/dist/vue-loading.css';
import LoadingOverlay from 'vue-loading-overlay';


let LoadingOverlayCnf = {color:"#242422",loader:"spinner",width:100,height:200}
Vue.use(LoadingOverlay, LoadingOverlayCnf)
Vue.component('LoadingOverlay',LoadingOverlay)

/* Link Docs : https://github.com/gilbitron/laravel-vue-pagination */
Vue.component("Pagination",()=>import("laravel-vue-pagination"))

Vue.component("VRatio",()=>import("@components/VRatio.vue"))
Vue.component("VImg",()=>import("@components/VImg.vue"))
Vue.component("VDesigner",()=>import("@components/VDesigner.vue"))
Vue.component("LoadingSpinner",()=>import("@components/LoadingSpinner.vue"))
Vue.component("LoadingSpinnerFe",()=>import("@components/LoadingSpinnerFe.vue"))
Vue.component("ProgressBar",()=>import("@components/ProgressBar.vue"))
Vue.component("FileManager",()=>import("@components/FileManager.vue"))
Vue.component("LabelError",()=>import("@components/LabelError.vue"))

/* Form Component */
Vue.component("VForm",()=>import("@forms/VForm.vue"))
Vue.component("FeField",()=>import("@forms/FeField.vue"))
Vue.component("Uploader",()=>import("@forms/Uploader.vue"))
Vue.component("Select2",()=>import("@forms/Select2/Select2.vue"))
Vue.component("Select2Channel",()=>import("@forms/Select2/Select2Channel.vue"))
Vue.component("TagsInput",()=>import("@forms/TagsInput.vue"))
Vue.component("Radio",()=>import("@forms/Radio.vue"))
Vue.component("Checkbox",()=>import("@forms/Checkbox.vue"))
Vue.component("DatePicker",()=>import("@forms/DatePicker/DatePicker.vue"))
Vue.component("DateTimePicker",()=>import("@forms/DatePicker/DateTimePicker.vue"))
Vue.component("DatePickerFE",()=>import("@forms/DatePicker/DatePickerFE.vue"))
Vue.component("IonRange",()=>import("@forms/IonRange/IonRange.vue"))
Vue.component("ImageFilter",()=>import("@forms/ImageFilter.vue"))
Vue.component("RangeSlider",()=>import("@forms/RangeSlider/RangeSlider.vue"))
Vue.component("CKEditor",()=>import("@forms/CKEditor/CKEditor.vue"))
Vue.component("ColorPicker",()=>import("@forms/ColorPicker.vue"))
Vue.component("InputCurrency",()=>import("@forms/InputCurrency.vue"))
Vue.component("InputSuggest",()=>import("@forms/InputSuggest.vue"))

/* loader page */
Vue.component("LoaderPage",()=>import("@frontend/part/LoaderPage.vue"))

export default {
  name:"App",
  computed:{
    baseUrl(){ return env.baseUrl },
    LoadingOverlayCnf(){ return LoadingOverlayCnf }
  },
  mounted(){
    var self = this
  },
  watch:{
    $route(){
      this.$root.pathname = location.pathname
    }
  }
};
</script>
<style lang="scss" scoped>
  .splash_screen{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
</style>
<template>
  <div id="app">
    <router-view/>
    <LoadingOverlay :active.sync="$root.var.loadingOverlay" v-bind="LoadingOverlayCnf"></LoadingOverlay>
    <div class="splash_screen" v-if="!$root.ready">
      <LoaderPage></LoaderPage>
    </div>
  </div>
</template>