import Gen from "@helper/Gen";
import BOGen from "./helper/BOGen";

var bo_router = [
  {
    path: "dashboard",
    name: "BoDashboard",
    component: () => import("@backend/Dashboard.vue"),
  },
  {
    path: "home/:id?",
    name: "BoHome",
    component: () => import("@backend/BoHome.vue"),
  },
  {
    path: "category-news-article/:id?",
    name: "BoNewsCategory",
    component: () => import("@backend/BoNewsCategory.vue"),
  },
  {
    path: "news-article/:id?",
    name: "BoNews",
    component: () => import("@backend/BoNews.vue"),
  },
  {
    path: "category-gallery/:id?",
    name: "BoGalleryCategory",
    component: () => import("@backend/BoGalleryCategory.vue"),
  },
  {
    path: "gallery/:id?",
    name: "BoGallery",
    component: () => import("@backend/BoGallery.vue"),
  },
  {
    path: "academic-calendar/:id?",
    name: "BoCalendar",
    component: () => import("@backend/BoCalendar.vue"),
  },
  {
    path: "curriculum/:id?",
    name: "BoCurriculum",
    component: () => import("@backend/BoCurriculum.vue"),
  },
  {
    path: "prestasi/:id?",
    name: "BoPrestasi",
    component: () => import("@backend/BoPrestasi.vue"),
  },
  {
    path: "fasilitas/:id?",
    name: "BoFasilitas",
    component: () => import("@backend/BoFasilitas.vue"),
  },
  {
    path: "testimoni/:id?",
    name: "BoTestimoni",
    component: () => import("@backend/BoTestimoni.vue"),
  },
  {
    path: "static-seo/:id?",
    name: "StaticSeo",
    component: () => import("@backend/BoStaticSeo.vue"),
  },
  {
    path: "legal-page/:id?",
    name: "Legal",
    component: () => import("@backend/BoLegal.vue"),
  },
  {
    path: "mr-template/:id?",
    name: "MrTemplate",
    component: () => import("@backend/BoMrTemplate.vue"),
  },
  {
    path: "web-settings/:id?",
    name: "WebSettings",
    component: () => import("@backend/BoWebSettings.vue"),
  },
  {
    path: "contact",
    name: "BoContact",
    component: () => import("@backend/BoContact.vue"),
  },
  {
    path: "users/:id?",
    name: "BoUsers",
    component: () => import("@backend/Users.vue"),
  },
  {
    path: "user-level/:id?",
    name: "BoUserLevel",
    component: () => import("@backend/UserLevel.vue"),
  },
  {
    path: "profile/:id?",
    name: "BoProfile",
    component: () => import("@backend/Profile.vue"),
  },
  {
    path: "about-sdi/:id?",
    name: "BoAboutSdi",
    component: () => import("@backend/BoAboutSdi.vue"),
  },
  {
    path: "about-yayasan/:id?",
    name: "BoAboutYayasan",
    component: () => import("@backend/BoAboutYayasan.vue"),
  },
  {
    path: "institution/:id?",
    name: "BoInstitution",
    component: () => import("@backend/BoInstitution.vue"),
  },
  {
    path: "ppdb/:id?",
    name: "BoPPDB",
    component: () => import("@backend/BoPPDB.vue"),
  },
  // 404
  { path: "*", redirect: { name: "BoDashboard" } },
];

var menuBypassRole = ["BoProfile"];

bo_router.map((v) => {
  v.beforeEnter = (to, from, next) => {
    var user = BOGen.user();
    if (!user || !Gen.getStorage("botk")) return next({ name: "BoLogin" });
    if (menuBypassRole.indexOf(to.name) > -1) return next();
    if (user.bu_level == 0) return next();
    if (user.menuRole.indexOf(to.name) == -1)
      return next({ name: user.menuRole[0] });
    next();
  };
  v.meta = { bo_protected: true };
});

var data = [
  {
    name: "BoLogin",
    path: "login",
    component: () => import("@backend/Login.vue"),
    beforeEnter: (to, from, next) => {
      var user = BOGen.user();
      if (user && Gen.getStorage("botk")) return next({ name: "BoDashboard" });
      next();
    },
  },
  {
    name: "BoPassRecover",
    path: "password-recovery/:code?",
    component: () => import("@backend/BoPassRecover.vue"),
  },
  // FileManager
  {
    name: "FileManager",
    path: "file-explore",
    component: () => import("@components/FileManagerPage.vue"),
  },
  {
    path: "",
    component: () => import("@/layout/BoLayout.vue"),
    redirect: { name: "BoDashboard" },
    children: bo_router,
  },
];

export default data;
