import App from './App.vue';
import Router from 'vue-router'
import Vue from 'vue';
import fo_router from './fo_router';
import bo_router from './bo_router';
import Gen from '@helper/Gen';
import VueDirective from './helper/VueDirective';
import NProgress from 'vue-nprogress'
//import './registerServiceWorker';
import { Plugin } from 'vue-fragment'
Vue.use(Plugin)
Vue.use(NProgress)
const nprogress = new NProgress()

Vue.config.productionTip = false
Vue.config.devtools = true

Vue.use(Router)

Object.keys(VueDirective).forEach((k)=>{
    Vue.directive(k, VueDirective[k])
})

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: "/backoffice",
            component: () => import('./layout/BoMain.vue'),
            redirect: {name:'BoLogin'},
            children: bo_router
        },
        {
            path: '/',
            component: () => import('./layout/FoMain.vue'),
            redirect: {name:"index"},
            children: fo_router
        },
  ]
})

router.beforeEach((to, from, next) => {
    next()
})

global.App = new Vue({
    nprogress,
    router,
    render: h => h(App),
    data(){
        return {
            ready: false,
            scrollTop: 0,
            width: 0,
            base: {},
            baseUrl: location.origin,
            pathname: location.pathname,
            var: {loadingOverlay: false},
            meta: {},
            page: {filter:{},menuRole(){}},
            user: {menuRole:[],moduleRole:[]},
            setting: {},
            fields: {},
            web: {},
            config: {mrValidation:{}},
            app:{
                path:{},
                uploader:{}
            },
            topProgress: null,
            store:{
                productMenu:[],
            }
        }
    },
    mounted(){
        // Resize
        this.width = window.innerWidth
        window.addEventListener("resize", ()=>{
            this.width = window.innerWidth > 0 ? window.innerWidth : screen.width
        })
        window.addEventListener("scroll", ()=>{
            this.scrollTop = window.scrollY
        })
    },
    computed:{
        isMobile(){ return this.width < 768 },
        isTablet() { return this.width >= 768 && this.width < 991 },
    },
    watch:{   
        base(v){ global.Base = v },
        page(v){ global.Page = v },
    }
}).$mount('#app')